import { createApp } from 'petite-vue';
import modal from '../components/modal/modal.js';

createApp({
  // exposed to all expressions
  count: 0,
  // getters - computed properties
  get plusOne() {
    return this.count + 1
  },
  // methods
  increment() {
    this.count++
  },

  // spread modal object into main app
  ...modal,

  mounted() {
    console.log('mounted');
  },
  umounted() {
    document.removeEventListener('keydown', this.keyupListener);
  }
}).mount()
