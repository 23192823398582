import { nextTick } from "petite-vue";

export default modal = {
  showModal: false,
  modalElement: null,

  openModal() {
    this.showModal = true;
    nextTick(() => {
      this.modalElement = document.querySelector('.modal-content');
      this.initModalKeyListener();
    });
  },

  closeModal() {
    this.modalElement.classList.add('fadeOut');

    setTimeout(() => {
      this.modalElement.classList.remove('fadeOut');
      this.showModal = false;
    }, 450);
  },

  initModalKeyListener() {
    if (!this.modalElement) return;
    document.addEventListener('keydown', this.keyupListener);
  },

  keyupListener(event) {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  },

}
